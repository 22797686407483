import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'app-button',
  imports: [CommonModule, MatIconModule, NgbTooltipModule],
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() label?: string;
  @Input() icon?: string;
  @Input() disabled = false;
  @Input() visible = true;
  @Input() selected = false;
  @Input() href?: string;
  @Input() target: '_blank' | '_self' | '_parent' | '_top' | string = '_blank';
  @Input() type: 'primary' | 'secondary' | 'destructive' | 'navigation' | 'link' = 'primary';
  @Input() use: 'default' | 'block' | 'menu' = 'default';
  @Input() working = false;
  @Input() svg?: string;
  @Input() tooltip?: string | TemplateRef<any> | null | undefined;
  @Input() triggerTooltip = 'hover';
  @Input() tooltipClass = '';
  @Input() rightIcon = false;
  @Input() ariaLabel?: string | undefined;
  @Input() smallIcon = false;

  /**
   * The type of the button.
   * Possible values are: 'button', 'submit', 'reset'.
   * Defaults to 'button'.
   */
  @Input({ required: false }) btnType: 'button' | 'submit' | 'reset' = 'button';

  @Output() clicked = new EventEmitter();

  onClicked() {
    if (!this.working) {
      this.clicked.emit();
    }
  }
}
